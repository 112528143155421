import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, reactive, toRaw, computed, toRefs, ref, onMounted } from 'vue';
import useTablePagination from '@/hooks/pagination';
import Cookies from "js-cookie";
import { selectCheckindayByPagination } from "/src/api/attendance";
const columnsDay01 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '上班打卡时间',
  dataIndex: 'workClockInFirst',
  slots: {
    customRender: 'workClockInFirst'
  }
}, {
  title: '上班打卡结果',
  dataIndex: 'workClockInFirstResult'
}, {
  title: '下班打卡时间',
  dataIndex: 'closeClockFirst',
  slots: {
    customRender: 'closeClockFirst'
  }
}, {
  title: '下班打卡结果',
  dataIndex: 'closeClockFirstResult'
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay02 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay03 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay04 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay05 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult'
  }]
}, {
  title: '第五次上班',
  dataIndex: 'payNode9',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFifth',
    slots: {
      customRender: 'workClockInFifth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFifthResult'
  }]
}, {
  title: '第五次下班',
  dataIndex: 'payNode10',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFifth',
    slots: {
      customRender: 'closeClockFifth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFifthResult'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay06 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult'
  }]
}, {
  title: '第五次上班',
  dataIndex: 'payNode9',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFifth',
    slots: {
      customRender: 'workClockInFifth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFifthResult'
  }]
}, {
  title: '第五次下班',
  dataIndex: 'payNode10',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFifth',
    slots: {
      customRender: 'closeClockFifth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFifthResult'
  }]
}, {
  title: '第六次上班',
  dataIndex: 'payNode11',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSixth',
    slots: {
      customRender: 'workClockInSixth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSixthResult'
  }]
}, {
  title: '第六次下班',
  dataIndex: 'payNode12',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSixth',
    slots: {
      customRender: 'closeClockSixth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSixthResult'
  }]
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay1 = [{
  title: '日期',
  dataIndex: 'date',
  width: 110,
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '上班打卡时间',
  dataIndex: 'workClockInFirst',
  slots: {
    customRender: 'workClockInFirst'
  }
}, {
  title: '上班打卡结果',
  dataIndex: 'workClockInFirstResult'
}, {
  title: '下班打卡时间',
  dataIndex: 'closeClockFirst',
  slots: {
    customRender: 'closeClockFirst'
  }
}, {
  title: '下班打卡结果',
  dataIndex: 'closeClockFirstResult'
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay0 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '休息日上班上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockStartTime',
    slots: {
      customRender: 'restClockStartTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockStartTimeResult'
  }]
}, {
  title: '休息日上班下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'restClockEndTime',
    slots: {
      customRender: 'restClockEndTime'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'restClockEndTimeResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay2 = [{
  title: '日期',
  dataIndex: 'date',
  width: 110,
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay3 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay4 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay5 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult'
  }]
}, {
  title: '第五次上班',
  dataIndex: 'payNode9',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFifth',
    slots: {
      customRender: 'workClockInFifth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFifthResult'
  }]
}, {
  title: '第五次下班',
  dataIndex: 'payNode10',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFifth',
    slots: {
      customRender: 'closeClockFifth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFifthResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
const columnsDay6 = [{
  title: '日期',
  dataIndex: 'date',
  fixed: 'left'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '当日工作时间',
  dataIndex: 'workTimeTrue',
  slots: {
    customRender: 'workTimeTrue'
  }
}, {
  title: '第一次上班',
  dataIndex: 'payNode1',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFirst',
    slots: {
      customRender: 'workClockInFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFirstResult'
  }]
}, {
  title: '第一次下班',
  dataIndex: 'payNode2',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFirst',
    slots: {
      customRender: 'closeClockFirst'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFirstResult'
  }]
}, {
  title: '第二次上班',
  dataIndex: 'payNode3',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSecond',
    slots: {
      customRender: 'workClockInSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSecondResult'
  }]
}, {
  title: '第二次下班',
  dataIndex: 'payNode4',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSecond',
    slots: {
      customRender: 'closeClockSecond'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSecondResult'
  }]
}, {
  title: '第三次上班',
  dataIndex: 'payNode5',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInThird',
    slots: {
      customRender: 'workClockInThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInThirdResult'
  }]
}, {
  title: '第三次下班',
  dataIndex: 'payNode6',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockThird',
    slots: {
      customRender: 'closeClockThird'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockThirdResult'
  }]
}, {
  title: '第四次上班',
  dataIndex: 'payNode7',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFourth',
    slots: {
      customRender: 'workClockInFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFourthResult'
  }]
}, {
  title: '第四次下班',
  dataIndex: 'payNode8',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFourth',
    slots: {
      customRender: 'closeClockFourth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFourthResult'
  }]
}, {
  title: '第五次上班',
  dataIndex: 'payNode9',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInFifth',
    slots: {
      customRender: 'workClockInFifth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInFifthResult'
  }]
}, {
  title: '第五次下班',
  dataIndex: 'payNode10',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockFifth',
    slots: {
      customRender: 'closeClockFifth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockFifthResult'
  }]
}, {
  title: '第六次上班',
  dataIndex: 'payNode11',
  children: [{
    title: '打卡时间',
    dataIndex: 'workClockInSixth',
    slots: {
      customRender: 'workClockInSixth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'workClockInSixthResult'
  }]
}, {
  title: '第六次下班',
  dataIndex: 'payNode12',
  children: [{
    title: '打卡时间',
    dataIndex: 'closeClockSixth',
    slots: {
      customRender: 'closeClockSixth'
    }
  }, {
    title: '打卡结果',
    dataIndex: 'closeClockSixthResult'
  }]
}, {
  title: '加班时长(小时)',
  dataIndex: 'overTotalHours'
} // {
//   title: '操作',
//   dataIndex: 'operation',
//   slots: {
//     customRender: 'operation',
//   },
// }
];
export default {
  setup() {
    const isShowRestDay = ref(false);
    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      selectCheckindayByPagination1();
    };

    const daySate = reactive({
      timesNum: 1,
      state: 0
    });
    const tableColumnsPeople = computed(() => {
      if (isShowRestDay.value) {
        if (daySate.timesNum === 1) {
          return columnsDay01;
        }

        if (daySate.timesNum === 2) {
          return columnsDay02;
        }

        if (daySate.timesNum === 3) {
          return columnsDay03;
        }

        if (daySate.timesNum === 4) {
          return columnsDay04;
        }

        if (daySate.timesNum === 5) {
          return columnsDay05;
        }

        if (daySate.timesNum === 6) {
          return columnsDay06;
        }
      } else {
        if (daySate.timesNum === 1) {
          return columnsDay1;
        }

        if (daySate.timesNum === 2) {
          return columnsDay2;
        }

        if (daySate.timesNum === 3) {
          return columnsDay3;
        }

        if (daySate.timesNum === 4) {
          return columnsDay4;
        }

        if (daySate.timesNum === 5) {
          return columnsDay5;
        }

        if (daySate.timesNum === 6) {
          return columnsDay6;
        }
      }
    });
    const formState = reactive({
      data: []
    });
    const loading = ref(false);
    let tableDataDay = ref([]); //获取考勤日列表

    const selectCheckindayByPagination1 = async () => {
      let [startDate, endDate] = formState.data;
      let param = {
        userName: Cookies.get('nickName'),
        workNo: Cookies.get('workNo'),
        type: 2,
        startDate,
        endDate,
        pageNumber: current.value ? current.value : 1,
        limit: pageSize.value ? pageSize.value : 10
      };
      loading.value = true;
      let res = await selectCheckindayByPagination(param);

      if (res.code === 200) {
        loading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        tableDataDay.value = [];
        let timesNum = [];

        if (list && list.length) {
          list.filter((item, index) => {
            tableDataDay.value.push({
              key: index + 1,
              date: item.createTime,
              name: item.userName,
              workNo: item.workNo,
              departmentName: item.departmentName,
              amTime: (item.firstAmTime == null ? '未打卡' : item.firstAmTime) + '~' + (item.lastAmTime == null ? '未打卡' : item.lastAmTime),
              pmTime: (item.firstPmTime == null ? '未打卡' : item.firstPmTime) + '~' + (item.lastPmTime == null ? '未打卡' : item.lastPmTime),
              overStartEndTime: item.overStartTime == null && item.overEndTime == null ? '未加班' : (item.overStartTime == null ? '未打卡' : item.overStartTime) + '~' + (item.overEndTime == null ? '未打卡' : item.overEndTime),
              isLate: item.isLate,
              isEarly: item.isEarly,
              reason: item.reason,
              // overtime: item.overHours,
              state: item.state,
              isUpdated: item.isUpdated,
              firstAmTime: item.firstAmTime,
              lastAmTime: item.lastAmTime,
              firstPmTime: item.firstPmTime,
              lastPmTime: item.lastPmTime,
              overStartTime: item.overStartTime,
              overEndTime: item.overEndTime,
              userNo: item.userNo,
              id: item.id,
              workClockInFirst: item.workClockInFirst,
              workClockInFirstResult: item.workClockInFirstResult,
              closeClockFirst: item.closeClockFirst,
              closeClockFirstResult: item.closeClockFirstResult,
              workClockInSecond: item.workClockInSecond,
              workClockInSecondResult: item.workClockInSecondResult,
              closeClockSecond: item.closeClockSecond,
              closeClockSecondResult: item.closeClockSecondResult,
              workClockInThird: item.workClockInThird,
              workClockInThirdResult: item.workClockInThirdResult,
              closeClockThird: item.closeClockThird,
              closeClockThirdResult: item.closeClockThirdResult,
              restClockStartTime: item.restClockStartTime,
              restClockStartTimeResult: item.restClockStartTimeResult,
              restClockEndTime: item.restClockEndTime,
              restClockEndTimeResult: item.restClockEndTimeResult,
              workClockInFourth: item.workClockInFourth,
              workClockInFourthResult: item.workClockInFourthResult,
              closeClockFourth: item.closeClockFourth,
              closeClockFourthResult: item.closeClockFourthResult,
              workClockInFifth: item.workClockInFifth,
              workClockInFifthResult: item.workClockInFifthResult,
              closeClockFifth: item.closeClockFifth,
              closeClockFifthResult: item.closeClockFifthResult,
              workClockInSixth: item.workClockInSixth,
              workClockInSixthResult: item.workClockInSixthResult,
              overTotalHours: item.overTotalHours,
              workTimeTrue: {
                workingTimeFirst: item.workingTimeFirst,
                closingTimeFirst: item.closingTimeFirst,
                restStartTime: item.restStartTime,
                restEndTime: item.restEndTime,
                workingTimeSecond: item.workingTimeSecond,
                closingTimeSecond: item.closingTimeSecond,
                workingTimeThird: item.workingTimeThird,
                closingTimeThird: item.closingTimeThird,
                restClockStartTime: item.restClockStartTime,
                restClockEndTime: item.restClockEndTime,
                workClockInFourth: item.workClockInFourth,
                closeClockFourth: item.closeClockFourth,
                workClockInFifth: item.workClockInFifth,
                closeClockFifth: item.closeClockFifth,
                workClockInSixth: item.workClockInSixth,
                closeClockSixth: item.closeClockSixth,
                workingTimeFourth: item.workingTimeFourth,
                closingTimeFourth: item.closingTimeFourth,
                workingTimeFifth: item.workingTimeFifth,
                closingTimeFifth: item.closingTimeFifth,
                workingTimeSixth: item.workingTimeSixth,
                closingTimeSixth: item.closingTimeSixth,
                state: item.state,
                timesNum: item.timesNum
              },
              timesNum: item.timesNum,
              state: item.state
            });
            daySate.state = item.state; // daySate.timesNum = item.timesNum

            isShowRestDay.value = item.isShowRestDay;
            timesNum.push(item.timesNum);
          });
          daySate.timesNum = Math.max(...timesNum);
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    };

    const onSubmit = () => {
      current.value = 1;
      selectCheckindayByPagination1();
    };

    onMounted(() => {
      selectCheckindayByPagination1();
    });
    return {
      tableColumnsPeople,
      loading,
      formState,
      tableDataDay,
      onSubmit,
      onPageChange,
      paginationConfig,
      daySate
    };
  }

};